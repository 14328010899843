* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

.index-page {
  height: auto;
}

p {
  text-transform: none;
}

nav.navbar.bootsnav ul.nav > li > a {
  text-transform: none;
}

nav.navbar.bootsnav.navbar-fixed {
  background-color: #f6f8fb;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}

.block-header {
  font-size: 46px;
  font-weight: 600;
}

.main-banner {
  min-height: 0 !important;
  p {
    text-transform: none;
  }
}

@media screen and (min-width: 768px) {
  .main-banner {
    padding-top: 287px;
  }
}

@media screen and (min-width: 373px) and (max-width: 767px) {
  .main-banner {
    padding: 7% 0;
  }
}

.navbar-right {
  .fa-sign-in {
    padding-right: 10px;
  }
}

@media screen and (max-width: 992px) {
  .navbar-default .navbar-brand img {
    max-width: 135px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1023px) {
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 24px 10px;
  }
}

@media screen and (max-width: 992px) {
  .main-banner {
    padding-top: 90px;
  }
}

.theme-cl {
  color: #ee1c25 !important;
}

nav.navbar.bootsnav ul.nav > li > a.btn-signup.red-btn {
  background: #ee1c25 !important;
}

.theme-btn {
  background: #ee1c25;
  border: 1px solid #ee1c25;
}

.log-wrapper {
  .btn-dark {
    margin-top: 29px;
    background: #334e6f;
    padding: 13px 25px !important;
    &:hover {
      outline: none;
    }
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    font-size: 12px;
  }
}

.log-wrapper {
  p {
    text-transform: none;
    margin: 16px;
  }
}

.logo-scrolled {
  margin-top: -6px;
}

nav.navbar.bootsnav ul.nav > li > a {
  color: #334e6f;
  font-weight: 600;
}

.heading h1 {
  text-transform: none;
}

.description-bus {
  position: relative;
  width: 100%;
  padding: 14px 20px 14px;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
}

.bus-details-btn {
  background-color: #ee1c25;
  border-color: #ee1c25;
  &:focus,
  &:hover {
    color: #fff;
    background: #ff4e00;
    border: 1px solid #ff4e00;
  }
}

.theme-btn:focus,
.theme-btn:hover {
  color: #fff;
  background: #ff4e00;
  border: 1px solid #ff4e00;
}

.dl-horizontal {
  margin-top: 12px;
}

.dl-system {
  margin-top: 12px;
  margin-bottom: 0;
}
.single-box-profile {
  margin-bottom: 20px;
}

.pagination-table {
  margin-bottom: 0;
}

.left-nav-profile {
  position: relative;
  min-height: 0;
  .img-circle {
    width: 140px;
    height: 140px;
    max-width: none;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    position: relative;
    height: 100%;
  }

  .left-nav-profile {
    position: absolute;
    height: 100%;
  }
}

input[type="file"] {
  display: none;
}

.save-changes-btn {
  margin: 20px 0;
  text-align: center;
}

.theme-bg {
  background-color: rgba(238, 28, 37, 1);
  background-image: url(../img/banner-log2.png), linear-gradient(to bottom, #ee1c25, #b3102e, #77132a, #3d121d, #000000);
}

.log-wrapper .btn-dark {
  background: #000;
}

.custom-error-message {
  list-style: none;
  text-align: left;
}

.group-btn-dark {
  width: 100%;
  &:hover,
  &:focus {
    background: #211d1d;
  }
}

.wp-login-submit {
  .group-btn-dark {
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.wp-signup-submit {
  .group-btn-dark {
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.wp-personal-data {
  input {
    margin-bottom: 15px;
  }
  margin-top: 29px;
  label {
    width: 90%;
    margin-bottom: 0;
    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    content: "\2713";
    color: transparent;
    display: inline-block;
    border: 2px solid #ffffff;
    font-size: 15px;
    font-weight: 900;
    line-height: 18px !important;
    margin: -5px 6px 0 0;
    height: 20px;
    width: 20px;
    text-align: center;
    vertical-align: middle;
    transition: color ease 0.3s;
  }

  input[type="checkbox"]:checked + label:before {
    color: #ffffff;
  }
  .label-checkout-payment {
    float: left;
  }
}

.modal-dialog {
  label {
    color: #70778b;
    font-weight: 500;
  }
  .custom-checkbox {
    color: #70778b;
  }
}

.custom-checkbox input[type="checkbox"]:checked + label:before {
  border-color: #ee1c25;
  background: #ee1c25;
}

.restoration-info {
  margin: 15px 0 !important;
}

.btn-dark-restoration {
  width: 100%;
  height: 50px;
  margin-top: 0 !important;
  &:hover,
  &:focus {
    background: #211d1d;
  }
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

.back-to-top:focus {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #ee1c25;
  color: #fff;
}
/* Back to top button */

/* app */
.app {
  text-align: center;
  vertical-align: center;
  font-size: 11px;
}
.theatre {
  white-space: nowrap;
}
.stage {
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 8px;
  font-size: 14px;
  color: gray;
  margin-bottom: 15px;
  border-radius: 50px;
  border-bottom: 2px solid gray;
  border-top: 0px;
}
.none-element {
  display: none;
}

.seat {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 3px;
  padding: 3px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #334e6f;

  user-select: none;

  &:nth-child(2) {
    /*margin-right: 7%;*/
    margin-right: 38px;
  }
}
.seat-category-0 {
  color: #334e6f;
  border-color: #334e6f;
  background: #f1f1f1;
}
.seat-category-r {
  color: gray;
  border-color: white;
}
.seat-category-1 {
  background: #334e6f;
  color: #fff;
}
.seat-category-2 {
  background: lightyellow;
  color: #fff;
}
.seat-category-3 {
  background: lightpink;
  color: lightpink;
}
/*
.seat-status-booked {
  background: #f1f1f1;
  color: #fff;
}
*/
.seat-status-booked {
  background: #f1f1f1;
  color: #334e6f;
}

.seat-status-reserved {
  background: #337ab7;
  color: #fff;
}
.seat-status-free:hover {
  border-color: dimgray;
  color: dimgray;
  cursor: pointer;
}
.seat-status-reserved:hover {
  border-color: black;
  color: lightgray;
  cursor: pointer;
}

.seat-category-undefined {
  &:hover,
  &:focus,
  &:active {
    background: #fff !important;
    border-color: #696969;
    color: #696969 !important;
    cursor: pointer;
  }
}

.prices {
  margin-top: 20px;
  margin-bottom: 20px;
}
.price {
  width: 150px;
  color: gray;
}
.reserved-seats {
  display: inline-block;
  color: black;
  text-align: left;
  font-size: 14px;
  width: 100%;
}
.reserved-seat {
  display: table;
  height: 100%;
  width: 100%;
  border-radius: 1px;
  border: 1px solid #dde6ef;
  padding: 12px 18px 12px;
  margin-bottom: 10px;
  label {
    color: #70778b;
    margin-bottom: 0;
  }
  input,
  select {
    height: 40px;
  }
}

.reserved-seat-category {
  float: right;
  color: #fff;
  background-color: #70778b;
  padding: 2px 8px;
  transition: all ease 0.4s;
  font-size: 12px;
  line-height: 16px;
  &:hover,
  &:focus {
    background: #ee1c25;
  }
}
.reserved-seat-category:hover {
  cursor: pointer;
}
.reserved-total {
  font-weight: bold;
  margin-bottom: 5px;
  float: right;
  font-size: 14px;
}

.reserved-total-label {
  padding-right: 5px;
  color: #000;
}
.reserved-total-price {
  color: #ee1c25;
  font-size: 16px;
}

.container-fluid {
  .btn-primary {
    border: 1px solid #ee1c25 !important;
    background: #ee1c25 !important;
    &:hover,
    &:focus {
      background: #ff4e00 !important;
      border: 1px solid #ff4e00 !important;
      outline: none !important;
    }
  }
}

.place-price {
  margin-top: 6px;
  label {
    float: right;
  }
  .wp-ticket-price-label {
    span {
      color: #000;
    }
  }
}

.prices {
  .seat {
    margin-right: 0;
  }
}

#app > div > div > div:nth-child(1) > div > div > div > div:nth-child(8) > div:nth-child(1) {
  margin-left: -69px;
}

#app > div > div > div:nth-child(1) > div > div > div > div:nth-child(16) > div:nth-child(2) {
  margin-right: 3px;
}

@media (max-width: 767.98px) {
  .wp-for-delete-button {
    margin-bottom: 27px;
  }
}

.seat-at-the-exit {
  margin-left: -77px;
}

.the-last-row {
  .seat:nth-child(2) {
    margin-right: 0px;
  }
}

/*
.seat-category-r {
  display: none;
}
*/
/* app */

/*==================================
    TIMELINE
==================================*/

$primary-color: #ee1c25;
.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

/*----- TIMELINE ITEM -----*/

.timeline-item {
  padding-left: 20px;
  position: relative;
}

/*----- TIMELINE INFO -----*/

.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0 0 0.1em 0;
  text-transform: uppercase;
  white-space: nowrap;
  span {
    padding-left: 1px;
  }
}
/*----- TIMELINE MARKER -----*/

.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
  &:before {
    background: $primary-color;
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
  }
  &:after {
    content: "";
    width: 3px;
    background: #ccd5db;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px;
  }
  .timeline-item:last-child &:after {
    content: none;
  }
}

.intermediate-points:before {
  background: transparent;
  border: 3px solid $primary-color;
}

.last-stop:before {
  background: transparent;
  border: none;
  content: "\f041";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  top: 4px;
  left: 0px;
  width: 15px;
  font-size: 26px;
}

.timeline-content {
  padding-bottom: 40px;
}
.pay-integration p {
  margin: 0;
}

/*----------------------------------------------
        MOD: TIMELINE SPLIT
    ----------------------------------------------*/

/*
.timeline-split {
  @media (min-width: 768px) {
    .timeline {
      display: table;
    }
    .timeline-item {
      display: table-row;
      padding: 0;
    }
    .timeline-info,
    .timeline-marker,
    .timeline-content,
    .period .timeline-info {
      display: table-cell;
      vertical-align: top;
    }
    .timeline-marker {
      position: relative;
    }
    .timeline-content { 
      padding-left: 30px;
    }
    .timeline-info {
      padding-right: 30px;
    }
    .period .timeline-title {
      position: relative;
      left: -45px;
    }
  }
}
*/

/*----------------------------------------------
        MOD: TIMELINE CENTERED
    ----------------------------------------------*/

.timeline-centered {
  /*@extend .timeline-split;*/
  @media (min-width: 992px) {
    &,
    .timeline-item,
    .timeline-info,
    .timeline-marker,
    .timeline-content {
      display: block;
      margin: 0;
      padding: 0;
    }
    .timeline-item {
      padding-bottom: 10px;
      overflow: hidden;
    }
    .timeline-marker {
      position: absolute;
      left: 50%;
      margin-left: -7.5px;
    }
    .timeline-info,
    .timeline-content {
      width: 50%;
    }

    > .timeline-item:nth-child(odd) .timeline-info {
      float: left;
      text-align: right;
      padding-right: 30px;
    }
    > .timeline-item:nth-child(odd) .timeline-content {
      float: right;
      text-align: left;
      padding-left: 30px;
    }
    > .timeline-item:nth-child(even) .timeline-info {
      float: left;
      text-align: right;
      padding-right: 30px;
    }
    > .timeline-item:nth-child(even) .timeline-content {
      float: right;
      text-align: left;
      padding-left: 30px;
    }
    > .timeline-item.period .timeline-content {
      float: none;
      padding: 0;
      width: 100%;
      text-align: center;
    }
    .timeline-item.period {
      padding: 50px 0 90px;
    }
    .period .timeline-marker:after {
      height: 30px;
      bottom: 0;
      top: auto;
    }
    .period .timeline-title {
      left: auto;
    }
  }
}

.wp-search-not-found {
  padding: 4em 0 4em 0;
  h2 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .wp-search-not-found .mini-banner-others-page-search {
    padding-top: 0;
  }
}

.wp-search-multi-option-booking {
  margin: 0 25px 10px;
  margin-top: 120px;
  form {
    width: 100%;
  }
  .seub-btn {
    width: 100%;
    padding: 19px 15px;
    height: 50px;
    height: auto;
    font-size: 15px;
    max-height: 60px;
  }

  .form-control:hover,
  .form-control:focus {
    border: 1px solid #dde6ef;
  }
  .form-search-trips fieldset .form-control {
    width: 100%;
    padding: 17px 15px;
    height: 50px;
    height: auto;
    font-size: 17px;
    text-align: left;
    max-height: 60px;
  }
  .form-search-trips fieldset .select2-container {
    width: 100%;
    padding: 17px 15px;
    height: 50px;
    height: auto;
    font-size: 17px;
    max-height: 60px;
  }
}

.select2-results .select2-highlighted {
  background-color: #ee1c25;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border: none;
}
.select2-drop-active {
  border: none;
}
.select2-container.select2-container-active .select2-choice {
  border: none;
  background-color: #fbfdff;
}

.arrows-between-cities {
  position: absolute;
  right: 0;
  color: #aaa;
  z-index: 2;
  cursor: pointer;
  border-left: 1px solid #dde6ef !important;
  height: 100%;
  padding: 0 20px;
  i {
    font-size: 16px;
    font-weight: 400;
    margin-top: 22px;
  }
}

@media only screen and (max-width: 767.98px) {
  .arrows-between-cities {
    i {
      transform: rotate(90deg);
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 767.98px) {
  .select2-container .select2-choice {
    padding: 0;
  }
  .select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 0;
  }

  .form-search-trips fieldset .form-control-where {
    padding: 19px 15px;
    padding-right: 70px !important;
  }

  .form-control-where .select2-allowclear .select2-choice .select2-chosen {
    margin-right: 0;
    margin-right: -30px;
  }

  #s2id_select2-option-1 > a > span.select2-chosen {
    margin-right: -52px;
  }

  #select2-option-2 {
    a {
      span {
        .select2-chosen {
          margin-right: 5px;
        }
      }
    }
  }

  .select2-container .select2-choice > .select2-chosen {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 425.98px) {
  .form-search-trips fieldset .form-control-where {
    padding: 19px 15px;
    padding-right: 70px !important;
  }
  .form-search-trips fieldset .form-control {
    text-align: left !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .wp-search-multi-option-booking {
    margin-top: 40px;
    .seub-btn {
      margin-top: 0;
    }
    .form-search-trips fieldset .form-control {
      text-align: center;
    }
  }

  .mini-banner-others-page-search {
    display: block;
    .wp-search-multi-option-booking {
      margin: 40px 0 10px;
    }
  }
}

/*
.gray-bg {
  min-height: 40%;
}
*/

.wp-search-gray-bg {
  padding: 2em 0 0em 0;
  .text-center {
    margin-bottom: 50px;
  }
}

.wp-block-search {
  background: #ffffff;
  padding: 15px 15px 14px;
}

.wp-block-search {
  .micro-timeline {
    background-color: transparent;
    height: auto;
    margin-right: 5px;
    width: 6%;
    .timeline-centered {
      margin-top: -20px;
      .timeline-item {
        padding-bottom: 35px;
        .timeline-marker:after {
          top: 21px;
        }
      }
    }
  }

  .first-block {
    display: inline-block;
    width: 90%;
    .city {
      margin-top: 2px;
    }
  }
  .center-block {
    margin-top: -1px;
    .city {
      margin-top: 1px;
    }
  }

  .icon-box-text {
    display: inline-block;
    vertical-align: middle;
  }
  .departure-time {
    font-size: 14px;
    color: #334e6f;
    font-weight: 600;
    padding-right: 10px;
    margin-bottom: 5px;
  }
  .departure-date {
    font-weight: 500;
    font-size: 14px;
    color: #70778b;
  }
  .flight-info {
    margin-bottom: 0px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #70778b;
    line-height: 20px;
  }
}

.wp-flight-details-button {
  .collapse-button {
    padding: 0;
    margin-top: 8px;
    color: #ee1c25;
    font-weight: 500;
  }
}

.wp-collapse-timeline {
  border-top: 2px solid #f3f4f7;
  margin: 10px 25px 0;
  .wrapper-timeline {
    .timeline-centered {
      margin-top: 15px;
    }
  }
  .wp-detail {
    font-weight: 500;
    position: relative;
    font-size: 15px;
    color: #70778b;
    font-family: "Montserrat", sans-serif;
    padding-right: 5px;
    .travel-time {
      padding-right: 5px;
    }
    .bus-info {
      padding-right: 5px;
      padding-left: 15px;
    }
  }
}

.wrapper-timeline {
  @media (min-width: 992px) {
    .timeline-centered {
      > .timeline-item:nth-child(odd) .timeline-info {
        float: left;
        text-align: right;
        padding-right: 20px;
      }
      > .timeline-item:nth-child(odd) .timeline-content {
        float: right;
        text-align: left;
        padding-left: 20px;
      }
      > .timeline-item:nth-child(even) .timeline-info {
        float: left;
        text-align: right;
        padding-right: 20px;
      }
      > .timeline-item:nth-child(even) .timeline-content {
        float: right;
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .wp-travel {
    display: block;
  }
  .wp-bus-info {
    display: block;
  }
  .wp-collapse-timeline .wp-detail .bus-info {
    padding-right: 0;
    padding-left: 0;
  }
  .wp-collapse-timeline {
    margin: 0;
    margin-top: 10px;
  }
}

.collapse-button {
  border: none !important;
  background-color: transparent;
  font-size: 14px;
  span {
    font-size: 10px;
  }
  &:focus,
  &:hover,
  &:active {
    outline: none !important;
    background-color: transparent;
  }
}

.right-block {
  .text-through {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    color: #334e6f;
  }
  .ticket-price {
    text-align: right;
    font-size: 17px;
    color: #ee1c25;
    font-weight: 600;
  }
  .wp-details-buy {
    margin-top: 15px;
    .number-places {
      position: relative;
      color: #ee1c25;
      font-weight: 500;
      font-size: 14px;
      .number-free-places {
        position: absolute;
        display: inline-block;
        margin-top: 17px;
      }
    }

    .buy-button {
      border-radius: 4px;
      margin-top: 0;
      float: right;
      padding: 5px 12px;
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}

@media (max-width: 575.98px) {
  .wp-search-gray-bg {
    .tr-single-body {
      padding: 2px 0px 2px;
    }
  }
}

@media only screen and (max-width: 359.98px) {
  .center-block {
    margin-left: 9%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 369.98px) {
  .center-block {
    margin-left: 9%;
  }
}

@media only screen and (min-width: 370px) and (max-width: 675.98px) {
  .center-block {
    margin-left: 8%;
  }
}

@media (min-width: 676px) {
  .center-block {
    margin-left: 7%;
  }
}

/* .center-block */

@media (max-width: 767.98px) {
  .right-block {
    margin-top: 10px;
    .text-through {
      margin-top: 0;
    }
    .wp-details-buy .number-places {
      padding: 0;
    }
  }
  .center-block {
    margin-top: 8px !important;
  }

  .wp-flight-details-button {
    .collapse-button {
      display: none;
      margin-top: -2px;
    }
  }

  .wp-block-search .micro-timeline {
    background-color: transparent;
    height: 26px;
    margin-right: 5px;
    width: 6%;
    .timeline-centered {
      margin-top: -20px;
      .timeline-item {
        padding-bottom: 73px;
      }
    }
  }

  .number-places {
    .wp-block-search .flight-info {
      display: inline-block !important;
    }
    .collapse-button {
      display: inline-block;
    }
    .wp-flight-details-button {
      display: inline-block;
      position: absolute;
      bottom: -33px;
    }
  }
  .right-block .wp-details-buy .number-places .number-free-places {
    position: absolute;
    display: inline-block;
    margin-top: -10px;
    left: 0;
  }
}

@media screen and (min-width: 768px) {
  .number-places {
    .collapse-button {
      display: none;
    }
  }
  .right-block .wp-details-buy .number-places .number-free-places {
    position: absolute;
    display: inline-block;
    margin-top: 17px;
    right: 0;
    margin-right: 95px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .right-block .wp-details-buy {
    margin-left: -15px;
  }
  .right-block .wp-details-buy .number-places .number-free-places {
    left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .wp-collapse-timeline {
    .wrapper-timeline {
      .timeline-centered {
        margin-left: -35%;
      }
    }
  }
  .timeline-title {
    margin-top: 4px;
  }
}

@media (max-width: 991.98px) {
  .wp-details-buy {
    margin-top: 5px;
    height: auto !important;
    .buy-button {
      border-radius: 4px;
      margin-top: 0;
      float: right;
      padding: 7px 14px;
    }
  }
  .timeline-content {
    padding-bottom: 20px;
    .timeline-title {
      margin: 0;
    }
  }
}

.additionally-panel-collapse {
  margin-top: 15px;
}

/* End search */

/* Successful */

.success-wrap {
  margin-top: 15px;
  font-weight: 500;
  .email-adress {
    font-weight: 600;
    color: #ee1c25;
  }
  .text-register {
    font-weight: 700;
    a {
      color: #ee1c25;
    }
  }
  .buy-a-ticket {
    font-size: 14px;
    margin: 3px 0 10px 0;
    i {
      padding-right: 5px;
    }
  }
  .wp-booking-timer {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #f21136;
    #bookingTimer {
      font-size: 20px;
      font-weight: 600;
      color: #f21136;
    }
  }
}
.wrap-not_successful {
  margin: 50px 0;
  p {
    margin-top: 50px;
  }
}

.wp-ticket-image {
  margin-bottom: 40px;
}
/* Successful End */

/* About */

#about {
  .ab-detail {
    p {
      font-weight: 500;
      font-size: 17px;
      font-family: "Montserrat", sans-serif;
      strong {
        color: #ee1c25;
      }
    }
  }
  .info-module {
    &:focus,
    &:hover {
      box-shadow: 0 0 18px 0 rgba(0, 5, 10, 0.55) !important;
    }
  }
}
/* About End*/

/* Contacts */

.wp-contacts {
  background: url("../img/Contacts2.jpg") no-repeat left top;
  position: relative;
}
@media (max-width: 767px) {
  .wp-contacts {
    background: url("../img/Contacts2.jpg") top;
  }
}

/* Contacts End*/

/* Bus */
.wp-common-block-bus {
  margin-bottom: 50px;
}

.bus-heading {
  margin: 40px 0 35px;
  padding: 0;
  span {
    font-size: 30px;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize;
    font-family: "Josefin Sans", sans-serif;
    margin-bottom: 0.25em;
    color: #334e6f;
  }
}

@media only screen and (min-width: 1024px) {
  .bus-heading span {
    font-size: 40px;
  }
}

.bus-slider #carousel {
  box-shadow: 0 0 18px 0 rgba(0, 5, 10, 0.55);
  margin: 0;
}

.bus-slider #thumbcarousel .item {
  text-align: center;
}

.bus-slider .item img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.bus-slider .tr-single-body {
  padding: 15px 25px 0;
  .amenities {
    margin-bottom: 0;
  }
}

.wp-bus-service {
  margin: 0 -15px;
  margin-bottom: 25px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .image-service {
    height: 52px;
    width: 52px;
    border: 1px solid #ccc;
    border-radius: 5px;
    float: left;
    margin: 15px 0 0 20px;
    background: 50% 50% no-repeat;
    background-size: 50% auto;
    border-radius: 5px;
    text-indent: -99999px;
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .wp-bus-service {
    margin: 0 -5px 15px;
    span {
      height: 36px;
      width: 36px;
      margin: 5px 0 0 0;
    }
  }
}

.bus-info-slider {
  box-shadow: 0 0 18px 0 rgba(0, 5, 10, 0.55);
  margin-bottom: 0;
  .tr-single-header {
    padding: 17px 25px 12px;
  }
}

.wrapper-bus-info {
  border-top: 1px solid #eee;
  padding: 10px 25px 10px;

  p {
    margin: 0;
  }
}

.separation-unit {
  text-align: center;
}

.spacer {
  position: relative;

  .mask {
    overflow: hidden;
    height: 10px;
    &:after {
      content: "";
      display: block;
      margin: -25px auto 0;
      width: 100%;
      height: 25px;
      border-radius: 125px / 12px;
      box-shadow: 0 0 8px black;
    }
  }
}

/**/
.carousel-wrap {
  margin: 5px 0;
  padding: 0px 10%;
  width: 100%;
  position: relative;
}

/* fix blank or flashing items on carousel */
.owl-carousel .item {
  position: relative;
  z-index: 100;
  -webkit-backface-visibility: hidden;
}
.owl-carousel .item iframe {
  width: 100%;
}

/* end fix */
.owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.owl-nav i {
  font-size: 52px;
}

.owl-nav .owl-prev {
  left: -30px;
}

.owl-nav .owl-next {
  right: -30px;
}

/* Bus End*/

/* navbar-phones */
.wp-left-dropdown-menu {
  position: absolute;
  top: -110%;
  left: 0;
}

.wp-center-dropdown-menu {
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  top: -110%;
  .social-img {
    padding: 0;
    border: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.wp-right-dropdown-menu {
  position: absolute;
  top: -110%;
  right: 0;
}

.wp-mini-center,
.wp-left-dropdown-menu,
.wp-center-dropdown-menu,
.wp-right-dropdown-menu {
  .dropdown,
  .wp-social-buttons {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    position: relative;
    font-size: 14px;
    color: white;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 0 0 0;
    margin-top: 20px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-itens,
  .wp-social-icon {
    color: inherit;
    text-transform: capitalize;
    text-decoration: none;
    display: block;
    text-align: left;
    margin: 1em 0;
    min-width: 190px;
    .img-flag-phones {
      display: inline-block;
      padding-right: 8px;
      margin-top: -1px;
    }
    i {
      margin-left: 5px;
    }
  }
}

.hero-text-center {
  h2 {
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .form-search-trips fieldset {
    .form-control {
      border-radius: 10px;
      text-align: center;
    }
    .seub-btn {
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .sl-box {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .form-search-trips fieldset {
    .form-control-where {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-right: 70px !important;
    }
    .seub-btn {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

@media (min-width: 993px) {
  .wp-left-dropdown-menu,
  .wp-center-dropdown-menu,
  .wp-right-dropdown-menu {
    top: -90%;
  }
}

@media (max-width: 767.98px) {
  .multi-option-booking {
    .fl-wrap {
      text-align: center;
    }
  }
  .wp-left-dropdown-menu,
  .wp-center-dropdown-menu,
  .wp-right-dropdown-menu {
    display: none;
  }

  .wp-mini-center {
    margin-top: 0;
    background: transparent;
    color: #ffffff;
    width: auto !important;
    max-width: 250px !important;
    float: none;
    display: inline-block;
    clear: both;
  }
  .list {
    color: #6b7c8a;
    font-weight: 500;
    li {
      margin-top: -8px;
    }
    a {
      cursor: pointer;
      font-weight: 500;
      line-height: 20px;
      list-style: none;
      padding-left: 18px;
      padding-right: 29px;
    }
  }
}
@media (max-width: 766.98px) {
  .wp-mini-center {
    margin-top: 25px;
  }
}

@media screen and (max-width: 372px) {
  .wp-mini-center {
    margin-top: 65px;
  }
  .main-banner p {
    margin-bottom: 20px;
  }
  .hero-text-center h2 {
    margin-top: 10px;
  }
  .main-banner {
    padding: 5% 0;
  }
}

@media screen and (min-width: 768px) {
  .wp-mini-center {
    display: none;
  }
}

/* navbar-phones End*/

/* Footer */

.wrapper-footer {
  .block-header {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    display: block;
  }
  .wp-footer-info-tel {
    li a {
      color: #fff !important;
      margin-bottom: 10px;
      i {
        padding-right: 15px;
        color: #ee1c25;
        font-weight: 800;
        font-size: 16px;
      }
      .fa-fw {
        width: 1.55em;
      }
    }
  }

  .footer-contact-information {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-bottom: 15px;
    .c-icon {
      font-size: 16px;
      float: left;
      margin-top: 5px;
    }
    .c-detail {
      margin-left: 28px;
    }
    span {
      font-size: 13px;
      line-height: 1.5;
      color: #fff !important;
      font-weight: 500;
      margin-bottom: 15px;
      opacity: 0.7;
    }
  }
  .wp-payment-systems {
    p {
      opacity: 0.7;
      font-size: 13px;
      line-height: 1.5;
      color: #fff !important;
      font-weight: 500;
    }
    img {
      margin-right: 10px;
      border-radius: 3px;
      max-width: 55px;
      height: auto;
      opacity: 1;
    }
  }
}

@media (max-width: 767.98px) {
  .wrapper-footer .block-header {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

/* Footer End */

/* Trips End */
.gray-bg-trips {
  padding: 35px 0 0;
  .page-title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .gray-bg-trips {
    .mini-banner-others-page-search .wp-search-multi-option-booking {
      margin: 15px 0 10px;
    }
  }
}

.main-wrapper-trips {
  margin-top: -80px;
  padding-top: 80px;
  padding-bottom: 0;
  .wrapper-trips-head {
    text-align: center;
    font-size: 36px;
    color: #334e6f;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 15px;
    .trips-head-name {
      display: block;
      margin-bottom: 15px;
      font-weight: 600;
    }
    .trips-head-name-small {
      display: block;
      margin-bottom: 35px;
      /*color: #000;*/
      color: #70778b;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .save-changes-btn {
    margin: 30px 0;
  }
  button {
    &::before {
      color: #ee1c25;
    }
  }
  .name-country {
    color: #ee1c25;
    font-weight: 500;
    margin: 0;
  }
  p {
    font-size: 14px;
    i {
      color: #ee1c25;
      font-weight: 500;
      font-size: 10px;
      margin: 0 5px;
    }
  }
}

.wrapper-trips-spacer {
  margin-bottom: 35px;
}

/* Trips End */

.sectionsforms {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 18px 0 rgba(0, 5, 10, 0.55);
}

.sectionsforms .contactforms {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5%;
  z-index: 99;
  background: linear-gradient(135deg, #1976bc 0%, #8dc645 100%);
  padding: 35px;
  box-sizing: border-box;
  max-width: 350px;
  box-shadow: 0 0 18px 0 rgba(0, 5, 10, 0.55);
  .p-title {
    font-size: 24px;
    color: #fff;
    line-height: 1.4;
  }
  .small {
    font-size: 15px;
    color: #fff;
  }
  .form-box {
    margin-top: 25px;
    padding: 0;
    background: transparent;
    border: none;
    color: #fff;
    margin-bottom: 0;
    i {
      color: #fff !important;
    }
  }
}

.btn-send-message {
  width: 100%;
}

@media (max-width: 767.98px) {
  .btn-send-message {
    font-size: 12px;
  }
}

.iframe-maps {
  width: 100%;
  height: 550px;
}

@media only screen and (max-width: 767.98px) {
  .sectionsforms .contactforms {
    position: relative;
    max-width: 100%;
    top: 0;
    -ms-transform: translateY(0);
    transform: translateY(0);
    left: 0;
  }
  .iframe-maps {
    height: 350px;
  }
}

/* offer-wrapper */

.offer-wrapper {
  background: #f7f7f7;
  min-height: 100vh;
  padding: 45px 0 35px 0;
  position: relative;
  .offer-text {
    margin: 0px 0 15px 0;
    text-indent: 2em;
    text-align: justify;
    font-size: 16px;
  }
  .offer-text-warning {
    color: red;
  }
  .great-title {
    font-size: 30px;
    text-align: center;
    margin: 0 0 20px 0;
    padding-top: 10px;
  }
}

.great-text-list {
  font-size: 22px;
  margin-bottom: 10px;
  padding-left: 5px;
  p {
    font-size: 16px;
    padding-left: 5px;
    text-align: justify;
    line-height: 1.8;
  }
}

.average-text-list {
  font-size: 16px;
  padding-left: 5px;
  text-align: justify;
  line-height: 1.8;
}

.average-text-list {
  &:first-child {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-wrapper {
    .container {
      max-width: 640px;
    }
  }
}

.offer-wrapper {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }
}

/* Transportation Rules */

.wrapper-transportation-rules {
  padding: 4em 0 4em 0;
  .wp-transportation-information-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #f21136;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #f21136;
    padding-bottom: 20px;
    margin-top: 25px;
  }
  .tr-single-box {
    h4 {
      font-weight: bold;
      color: #334e6f;
    }
    margin-bottom: 0;
    .list-transportation-info {
      li {
        width: 100%;
        font-size: 15px;
        margin-bottom: 10px;
        text-transform: lowercase;
        &::before {
          content: "\f22d";
          color: #70778b;
        }
      }
    }
    .list-transportation-forbidden {
      p {
        font-size: 16px;
      }
      li {
        width: 100%;
        text-transform: lowercase;
        &::before {
          content: "\f00d";
          color: #f21136;
        }
      }
    }
  }
}

/* Transportation Rules End*/

/* Action */

.wrapper-action {
  padding: 4em 0 4em 0;
  .wp-action-information-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #f21136;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #f21136;
    padding-bottom: 20px;
    margin-top: 25px;
  }

  .tr-single-box {
    margin-bottom: 0;
    .list-action-information {
      li {
        width: 100%;
        &::before {
          content: "\f054";
          color: #f21136;
        }
        strong {
          color: #f21136;
        }
      }
    }
    .list-action-information-warning {
      margin-top: 15px;
      border: 1px solid #f21136;
      border-radius: 10px;
      background: #eceff5;
      padding: 10px 20px;
      li {
        width: 100%;
        color: #f21136;
        font-weight: 500;
        &::before {
          content: "\f138";
          color: #f21136;
        }
      }
    }
  }
}

/* Action End*/

/* Return */

@media (max-width: 480px) {
  .banner-title h2 {
    font-size: 28px !important;
  }
}

.wrapper-return {
  padding: 4em 0 4em 0;
  .wp-action-return-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #f21136;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #f21136;
    padding-bottom: 20px;
    margin-top: 25px;
  }

  .tr-single-box {
    margin-bottom: 0;
  }
}

/* Return End*/

.wp-types-of-payment {
  padding: 0 15px;
  .payment-card-header {
    padding: 18px 0 15px;
    display: inline-table;
    cursor: pointer;
    min-height: 65px;
    h4 {
      margin-top: 6px;
    }
  }
  .booking-price-detail {
    font-weight: 600;
    p {
      font-weight: 500;
    }
    ul li {
      padding: 0 0 5px;
      margin: 0;
    }
    .pull-right {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .wp-personal-data {
    margin-top: 0;
    text-align: left;
    /* 
    padding: 15px 0;
    border-top: 1px solid #dde6ef !important;
    */
    label {
      font-size: 12px;
      a {
        color: #0066b3;
      }
    }
  }

  .payment-card-title.flexbox {
    float: none !important;
    text-align: left;
    margin-bottom: 5px;
  }

  .fa-pull-right,
  .pull-right {
    float: none !important;
  }

  .wp-personal-data input[type="checkbox"] + label:before {
    border: 2px solid #ee1c25;
  }

  .wp-personal-data input[type="checkbox"]:checked + label:before {
    color: #0fb76b;
    border: 2px solid #0fb76b;
  }
  .wp-success {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    button {
      width: 100%;
    }
  }
  .collapse {
    padding: 0;
  }
}

.wp-personal-data {
  li {
    margin-top: -10px;
    margin-bottom: 15px;
    color: #ee1c25;
    font-weight: 400;
    font-size: 14px;
    list-style: none;
  }
}

.payment-card-body {
  .custom-error-message {
    color: #ee1c25;
    font-weight: 400;
    font-size: 14px;
  }
}

.wp-types-of-payment-booking {
  margin-bottom: 0;
}

.container-fluid {
  .booking-price-detail {
    text-align: left;
    font-size: 14px;
    .booking-price-detail-item {
      padding: 6px 0;
    }
  }

  .tr-single-box {
    .booking-price-detail {
      border-bottom: 1px solid rgb(234, 239, 245);
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 369.98px) {
  .booking-price-detail-item {
    display: flex;
    .pull-right {
      padding-left: 15px;
    }
  }
}

/**/
.container-fluid {
  .tr-single-box {
    margin-bottom: 0px;
  }
  .tr-single-body {
    padding: 15px;
  }
  .wp-types-of-payment {
    margin-bottom: 15px;
  }
  .wp-types-of-payment-booking {
    margin-bottom: 0;
  }
}

.reserved-seat {
  .fl-box {
    .nice-select {
      height: 40px;
      line-height: 25px;
      width: 100%;
      .list {
        width: 100%;
      }
    }
  }
}

.seatSelection {
  text-align: center;
  padding: 5px;
  margin: 15px;
}

.seatsReceipt {
  text-align: center;
}

.seatNumber {
  display: inline;
  padding: 10px;
  background-color: #5c86eb;
  color: #fff;
  border-radius: 5px;
  cursor: default;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
}

.seatRow {
  text-align: center;
  padding: 5px;
  margin: 15px;
}

.seatSelected {
  background-color: lightgreen;
  color: black;
}

.seatUnavailable {
  background-color: gray;
}

.seatRowNumber {
  clear: none;
  display: inline;
}

.hidden {
  display: none;
}

.seatsAmount {
  max-width: 2em;
}

@media screen and (min-width: 993px) and (max-width: 1999px) {
  .navbar-default .navbar-brand {
    padding: 15px 0 15px 0;
    img {
      max-width: 150px;
    }
  }
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 24px 10px;
  }
}

/* background video */

/*
.video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url(../img/slid_photo.jpg) no-repeat center center/cover;
}
*/

.overlay {
  width: 100%;
  height: 110%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.1) 50%);
  background-size: 3px 3px;
  z-index: 2;
}

.slideshow-container-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}

/*
.slideshow-item,
.slideshow-container-video .slick-slide {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .main-banner {
    padding: 15% 0 5%;
    min-height: 650px;
    height: 65vh;
  }
}
*/

@media only screen and (min-width: 768px) and (max-width: 1023.9px) {
  .index-page {
    .main-banner {
      height: 100%;
      padding: 215px 0 9%;
      .hero-text-center {
        margin-top: -70px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200.9px) {
  .index-page {
    .main-banner {
      height: 100%;
      padding: 245px 0 12%;
      .hero-text-center {
        margin-top: -40px;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1440.9px) {
  .index-page {
    .main-banner {
      height: 100%;
      padding: 265px 0 17.5%;
    }
    .hero-text-center {
      margin-top: -30px;
    }
  }
}

@media only screen and (min-width: 1441px) {
  .index-page {
    .main-banner {
      height: 100%;
      padding: 290px 0 19.5%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .slideshow-container-video {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .slideshow-container {
    display: none;
  }
}

/* background video End*/

/* test*/

.main-wp-bus {
  /*
  article figure .listing-box-img {
    height: auto !important;
    max-height: 100%;
  }
*/
  .bus-carousel {
    width: 100%;
  }

  .bus-carousel .bus {
    box-sizing: border-box;
    margin: 0 10px;
    display: flex;
    flex-flow: column;
    align-content: space-between;
    /*box-shadow: 0 0 20px 0 rgba(62,28,131,0.1);*/
    background: #fff;
    font-size: 16px;
    background: #ffffff;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .bus-carousel .bus-top {
    width: 100%;
    .tour-box {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin: 0;
      box-shadow: none;
    }
    .name-bus {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.1;
    }
    .destination-place {
      i {
        padding-right: 5px;
      }
    }
  }

  .bus-carousel .bus-image {
    align-self: flex-start;
    width: 100%;
  }

  img.review-stars {
    width: 100px;
    display: inline-block;
  }

  .bus-carousel .bus-name {
    font-weight: bold;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .bus-carousel .bus-bottom {
    margin-bottom: auto;
    align-items: flex-end;
    padding: 14px 20px 14px;
    font-size: 16px;
    p {
      margin: 0;
    }
  }

  .bus-carousel .bus-prices {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 100%;
    align-self: center;
  }

  .bus-carousel .bus-details {
    background-color: #ee1c25;
    border-color: #ee1c25;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus,
    &:hover {
      color: #fff;
      background: #ff4e00;
      border: 1px solid #ff4e00;
    }
  }

  /** SLICK SLIDER CSS **/
  /* Slider */
  .slick-slider {
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    &:hover,
    &:focus {
      outline: none !important;
    }
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-active {
    height: auto !important;
  }

  .name-bus {
    bottom: 40px;
  }
}

.social-img {
  padding: 5px;
  border: 1px solid #3b495f;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  transition: all ease 0.4s;
}

.facebook-img {
  &:focus,
  &:hover {
    background: #3b5998;
    border-color: #3b5998;
  }
}

.google-plus-img {
  &:focus,
  &:hover {
    background: #ee1c25;
    border-color: #ee1c25;
  }
}

.viber-img {
  &:focus,
  &:hover {
    background: #6f3faa;
    border-color: #6f3faa;
  }
}

.whatsapp-img {
  &:focus,
  &:hover {
    background: #43c553;
    border-color: #43c553;
  }
}

.wrapper-footer {
  padding: 0;
  .container {
    padding: 30px 20px 25px 50px;
  }
}

/*
@media screen and (max-width: 768px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 30px 100px;
    }
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 25px 90px;
    }
  }
}
@media (min-width: 801px) and (max-width: 900px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 25px 75px;
    }
  }
}

@media (min-width: 901px) and (max-width: 991px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 25px 30px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 25px 100px;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .wrapper-footer {
    .container {
      padding: 30px 20px 25px 95px;
    }
  }
}
*/
/* news */

.wrapper-news {
  .spacer {
    margin: 10px 0 30px;
  }
}

.news-heading {
  margin: 40px 0 35px;
  padding: 0;
  span {
    font-size: 30px;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize;
    font-family: "Josefin Sans", sans-serif;
    margin-bottom: 0.25em;
    color: #334e6f;
  }
}

@media only screen and (min-width: 1024px) {
  .news-heading span {
    font-size: 40px;
  }
}

.gray-bg-news {
  padding: 6em 0 0 0;
  .tr-single-body {
    padding: 10px;
    p {
      &:last-child {
        margin: 0;
      }
    }
  }
}

.main-news-wrapper {
  .review-box {
    padding: 0;
    margin: 0;
    .review-box-content {
      padding: 0;
      margin: 0;
      border-bottom: none;
      p {
        &:last-child {
          margin: 0;
        }
      }
    }
    .review-thumb {
      background: none;
      margin-top: 10px;
      .img-circle {
        border-radius: 0;
      }
    }
  }
  .img-responsive {
    margin-bottom: 10px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
  }
}

.right-news-wrapper {
  padding: 10px 15px 10px;
  h4 {
    font-size: 12px;
    margin-top: 0;
  }
  span {
    font-size: 11px;
  }
  .review-box {
    border-bottom: 1px dashed #f3f4f7;
    .review-box-content {
      margin: 0;
      padding: 0;
      border: none;
    }
    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
    .review-thumb {
      background: none;
      padding: 0;
      height: auto;
      width: 85px;
      .img-circle {
        border-radius: 0;
      }
    }
  }
  .review-user-info {
    margin-left: 100px;
  }
}

.posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .posts-item {
    margin: 0 1%;
    display: flex;
    flex-direction: column;
    flex-basis: 31%;
    background-color: #fff;
    margin-bottom: 22px;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    overflow: hidden;
  }

  .posts-image {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 400ms ease-out;
      &:hover {
        transform: scale(1.15);
      }
    }
  }

  .posts-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    flex-grow: 1;
  }

  .posts-date {
    font-weight: 600;
    font-weight: 500;
    font-size: 14px;
    color: #334e6f;
    outline: none;
    text-decoration: none;
    color: #70778b;
    line-height: 18px;
  }

  .posts-title {
    margin-top: 10px;
    margin-bottom: auto;
    a {
      font-size: 18px;
      line-height: 1.3;
      text-decoration: none;
      /* letter-spacing: 0.55px;*/
      color: #334e6f;
      outline: none;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .posts-information {
    padding: 10px 15px 15px 15px;
  }
  .posts-date {
    font-size: 12px;
  }
  .posts-title a {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .posts {
    .posts-item {
      flex-basis: 48%;
    }
  }
}

@media screen and (max-width: 480px) {
  .posts {
    .posts-item {
      flex-basis: 100%;
    }
  }
}

.news-heading {
  margin: 30px 0 35px;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .news-heading {
    margin: 27px 0 20px;
    padding: 0;
  }
}
/* news End*/

/*
.company-header-avatar{
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
}
*/

/* wrapper-booked-tickets */
.wrapper-booked-tickets {
  margin-bottom: 15px;
  .table-responsive {
    table {
      margin-bottom: 0 !important;
    }
  }
  .end-of-booking {
    font-size: 13.5px;
    color: #f21136;
  }
  .tickets-to-buy {
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    .buy-a-ticket {
      text-transform: capitalize;
      i {
        padding-right: 5px;
      }
    }
    .text-register {
      margin: 0 auto;
      text-align: left;
      margin-top: 15px;
      font-weight: 700;
      font-size: 16px;
      a {
        color: #ee1c25;
      }
    }
  }
}
table.table td a {
  margin-bottom: 5px;
}

/* wrapper-booked-tickets End*/

/*
input[type=checkbox] {  
  box-shadow: 0 0 5px 2px red;
}
*/

.booking-not-user {
  color: #f21136;
  font-size: 20px !important;
  margin-bottom: 0.25em;
  margin-top: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  span {
    padding-left: 5px;
  }
}

.select2-container .select2-choice {
  background-color: #fbfdff;
  border: none;
}

.select2-container abbr:after {
  left: 0px;
}

.form-search-trips {
  .form-control {
    font-size: 17px !important;
  }
  .select2-choice {
    color: #6b7c8a;
    font-weight: 400;
    font-size: 17px;
  }
  .select2-chosen,
  .select2-drop-mask {
    color: #6b7c8a;
    font-weight: 400;
    font-size: 17px;
  }
  .select2-container .select2-choice {
    height: 30px;
    line-height: 23px;
  }
}

/**/

.personal-data-wrapper {
  background: #f7f7f7;
  min-height: 100vh;
  padding: 45px 0 35px 0;
  position: relative;
  .personal-data-text {
    margin: 0px 0 15px 0;
    text-indent: 2em;
    text-align: justify;
    font-size: 16px;
  }
  .personal-data-text-warning {
    color: red;
  }
  .great-title {
    font-size: 30px;
    text-align: center;
    margin: 0 0 20px 0;
    padding-top: 10px;
  }
  ol {
    padding: 0 15px 0 0;

    .great-text-list {
      margin: 0 0 5px 15px;
      text-align: justify;
      font-size: 16px;
    }
  }
}

/* phone button */

.wp-phone {
  left: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 9999;
  opacity: 0.8;
  .Phone {
    display: block;
    margin: 0;
    width: 0.33em;
    height: 0.33em;
    font-size: 25vmin;
    background-color: #3498db;
    border-radius: 0.5em;
    box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
  }

  .Phone::before,
  .Phone::after {
    position: absolute;
    content: "";
  }

  .Phone::before {
    top: 0;
    left: 0;
    width: 0.33em;
    height: 0.33em;
    background-color: rgba(#fff, 0.1);
    border-radius: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }

  .Phone::after {
    top: 0.08em;
    left: 0.08em;
    width: 0.16em;
    height: 0.16em;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate3d(0, 0, 0);
  }

  .Phone.is-animating {
    animation: phone-outer 3000ms infinite;

    &::before {
      animation: phone-inner 3000ms infinite;
    }

    &::after {
      animation: phone-icon 3000ms infinite;
    }
  }

  @keyframes phone-outer {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
      transform: translate3d(0, 0, 0) scale(1.1);
      box-shadow: 0 0 0 0em rgba(#3498db, 0.1), 0em 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0.5em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
    }
  }

  @keyframes phone-inner {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0);
    }
    33.3333% {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.9);
    }
    66.6666% {
      opacity: 0;
      transform: translate3d(0, 0, 0) scale(0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0) scale(0);
    }
  }

  @keyframes phone-icon {
    0% {
      transform: translate3d(0em, 0, 0);
    }
    2% {
      transform: translate3d(0.01em, 0, 0);
    }
    4% {
      transform: translate3d(-0.01em, 0, 0);
    }
    6% {
      transform: translate3d(0.01em, 0, 0);
    }
    8% {
      transform: translate3d(-0.01em, 0, 0);
    }
    10% {
      transform: translate3d(0.01em, 0, 0);
    }
    12% {
      transform: translate3d(-0.01em, 0, 0);
    }
    14% {
      transform: translate3d(0.01em, 0, 0);
    }
    16% {
      transform: translate3d(-0.01em, 0, 0);
    }
    18% {
      transform: translate3d(0.01em, 0, 0);
    }
    20% {
      transform: translate3d(-0.01em, 0, 0);
    }
    22% {
      transform: translate3d(0.01em, 0, 0);
    }
    24% {
      transform: translate3d(-0.01em, 0, 0);
    }
    26% {
      transform: translate3d(0.01em, 0, 0);
    }
    28% {
      transform: translate3d(-0.01em, 0, 0);
    }
    30% {
      transform: translate3d(0.01em, 0, 0);
    }
    32% {
      transform: translate3d(-0.01em, 0, 0);
    }
    34% {
      transform: translate3d(0.01em, 0, 0);
    }
    36% {
      transform: translate3d(-0.01em, 0, 0);
    }
    38% {
      transform: translate3d(0.01em, 0, 0);
    }
    40% {
      transform: translate3d(-0.01em, 0, 0);
    }
    42% {
      transform: translate3d(0.01em, 0, 0);
    }
    44% {
      transform: translate3d(-0.01em, 0, 0);
    }
    46% {
      transform: translate3d(0em, 0, 0);
    }
  }
}

.wp-phone-submit {
  margin-bottom: 0;
  .call {
    background-color: #3498db;
    margin-bottom: 10px;
  }
  .close-modal {
    background: #ee1c25;
    border: 1px solid #ee1c25;
  }
  .modal-footer {
    padding-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  .wp-phone .Phone {
    width: 80px;
    height: 80px;
    &:before {
      width: 80px;
      height: 80px;
    }
    &:after {
      top: 20px;
      left: 20px;
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 480px) {
  .wp-phone .Phone {
    width: 66px;
    height: 66px;
    &:before {
      width: 65px;
      height: 65px;
    }
    &:after {
      top: 16px;
      left: 16px;
      width: 32px;
      height: 32px;
    }
  }
}

#modalContactForm {
  top: 50%;
  transform: translateY(-50%);
  .text-modal {
    text-align: center;
  }
  label {
    margin-bottom: 10px;
  }
}

.list-slider-city {
  button {
    &::before {
      color: #ee1c25;
    }
  }
  .name-country {
    color: #ee1c25;
    font-weight: 500;
    margin: 0;
  }
  p {
    font-size: 14px;
    i {
      color: #ee1c25;
      font-weight: 500;
      font-size: 10px;
      margin: 0 5px;
    }
  }
}
/* phone button */

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .list-slider-city {
    padding: 0 25px;
    .slick-next {
      right: 5px;
    }
    .slick-prev {
      left: 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .slick-next,
  .slick-next {
    visibility: hidden !important;
    display: none !important;
  }
}

.page-title-wrap {
  h2 {
    margin-bottom: 15px;
    color: #fff;
  }
  p a {
    padding: 5px;
    border: 1px solid #dde6ef !important;
    font-size: 20px;
    font-weight: 500;
    color: #dde6ef !important;
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
}

nav.navbar.bootsnav.navbar-fixed {
  z-index: 999;
}

nav.navbar.bootsnav .navbar-toggle {
  background-color: inherit !important;
  font-size: 20px;
}

@media (max-width: 992px) {
  nav.navbar.bootsnav.no-full .navbar-collapse {
    max-height: 400px;
    overflow-y: auto !important;
    z-index: 999;
  }
}

nav.navbar.bootsnav ul.nav > li > a.btn-signup.red-btn {
  margin: 12px 0;
}

/*2022*/

@media only screen and (min-width: 768px) {
  .mini-banner-others-page {
    height: 350px !important;
  }
  .mini-banner-others-page-search {
    height: 350px !important;
  }
}

.youtube-img {
  &:focus,
  &:hover {
    background: #cc181e;
    border-color: #cc181e;
  }
}

.instagram-img {
  &:focus,
  &:hover {
    background: #fff;
    border-color: #fff;
  }
}

@media (max-width: 767.98px) {
  .br-right {
    border-bottom: 1px solid #334e6f;
    margin-bottom: 12px;
  }

  .navbar-social-icon {
    text-align: center;
    margin-bottom: 12px;
    .social-img {
      width: 36px;
      height: 36px;
    }
  } 
  nav.navbar.bootsnav.no-full .navbar-collapse {
    max-height: 421px;
    overflow-y: auto !important;
    z-index: 999;
  }
  .social-img {
    border: none;
  }
}

@media screen and (min-width: 768px) {
  .navbar-social-icon {
    display: none;
  }
}

@media (min-width: 993px) {
  .wp-center-dropdown-menu {
    .wp-social-icon {
      .social-img {
        width: 36px;
        height: 36px;
      }
    }
  }
}